.sg-setting-text {
  font-size: 16px;
  color: rgba(82, 81, 85, 0.68);
}

.sg-splash-version {
  margin-top: 10px;
}

.sg-splash-version span {
  font-size: 15px;
  color: black;
}

.sg-p-absolute {
  position: absolute;
}

.sg-p-top {
  top: 10px;
}

.sg-p-bottom {
  bottom: 10px;
}

.sg-p-left {
  left: 10px;
}

.sg-p-right {
  right: 10px;
}

.has-float-label span {
  font-size: 15px;
}

.sg-control-topic {
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 3rem;
}

.sg-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sg-red {
  color: rgba(248, 22, 33, 0.75);
}

.sg-black {
  color: rgb(16, 16, 16);
}

.sg-bg-red {
  background-color: rgba(248, 22, 33, 0.75);
}

.sg-green {
  color: rgba(30, 165, 62, 0.75);
}

.sg-bg-green {
  background-color: rgba(30, 165, 62, 0.75);
}

.sg-grey {
  color: rgba(82, 81, 85, 0.68);
}

.sg-ch-status-card {
  padding: 0 !important;
  height: 70px;
  display: flex;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

#ch-status-number {
  width: 80px;
}

#ch-status-number > span {
  font-size: 28px;
  font-weight: 700 !important;
}
